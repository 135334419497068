import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import { Layout } from "/src/components/layouts/default";
import Seo from "/src/components/helpers/seo";
import Webform from "gatsby-drupal-webform";

const IndexPage = ({ data }) => {
  const [isWebformMessage, setWebformMessage] = useState(false);
  const pages = data.allNodePage.nodes;
  const details = data.allNodeDetailPage.nodes;

  return (
    <Layout currentLang="en">
      <Seo title="Index EN" />
      <h1>Welcome to PWA test site</h1>
      <div className="twoColumns">
        <div className="articlesList">
          <h2>Here is some test pages:</h2>
          {pages.map((page) => (
            <div key={page.path.alias} className="article">
              <Link to={page.path.alias}>{page.title}</Link>
            </div>
          ))}
        </div>
        <div className="articlesList">
          <h2>Here is some test detail-pages:</h2>
          {details.map((page) => (
            <div key={page.path.alias} className="article">
              <Link to={page.path.alias}>{page.title}</Link>
            </div>
          ))}
        </div>
        <div className="articlesList">
          <Link to="/articles">List of articles</Link>
        </div>
      </div>
      <div className="contact-webform">
        {!isWebformMessage && (
          <Webform
            webform={data.webformWebform}
            endpoint="https://pwa.dev2.propal.net/react_webform_backend/submit"
            onSuccess={(response) => {
              setWebformMessage(response.settings.confirmation_message);
            }}
          />
        )}
        {isWebformMessage && (
          <div className="webform-success">{isWebformMessage}</div>
        )}
      </div>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ContentListsEN {
    allNodeArticle(filter: { langcode: { eq: "en" } }) {
      nodes {
        id
        body {
          processed
        }
        path {
          alias
        }
        title
      }
    }
    allNodePage(filter: { langcode: { eq: "en" } }) {
      nodes {
        body {
          processed
        }
        id
        path {
          alias
        }
        title
      }
    }
    allNodeDetailPage(filter: { langcode: { eq: "en" } }) {
      nodes {
        id
        path {
          alias
        }
        title
      }
    }
    webformWebform(drupal_internal__id: { eq: "contact" }) {
      drupal_internal__id
      elements {
        name
        type
        attributes {
          name
          value
        }
      }
    }
  }
`;
